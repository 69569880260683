// Typography
// 1. Extract to settings.
$font-size-base: 16px;
$line-height-base: 1.7;
$font-weight-bold: 700;
$headings-font-weight: $font-weight-bold;

// Colors
$brand-primary: #a48041;
$m-form-required-sign-color: #e91717;
$brand-success: #80bc68;
$separator-color: rgba(#000, 0.1); // ~#e4e4e4 on white background
$separator-color-light: rgba(#fff, 0.2);

// Layout
$grid-gutter-width: 32px;
$grid-gutter-height: $grid-gutter-width;
$base-spacing-px: 32px;
$container-large-desktop: (1370px + $grid-gutter-width);
$container-xlg: (1720px + $grid-gutter-width);
$screen-sm-min: 640px;
$container-sm: 100%;
$screen-md-min: 992px;
$screen-lg-min: 1400px;
$screen-xlg-min: 1750px;
$fixed-layout-breakpoint: $screen-lg-min;
$grid-float-breakpoint: $screen-md-min;
$border-radius-base: 0;
$border-radius-large: $border-radius-base;
$border-radius-small: $border-radius-base;
$minimax-m-content-padding: 55px;
$close-text-shadow: none;

// Modals
$modal-title-text-transform: uppercase;

// Tables
$table-cell-padding: 15px 8px;
$table-border-color: rgba(#000, 0.2);

// Labels
$labels-padding: .2em 1em .3em;
$labels-text-transform: uppercase;
$labels-font-weight: normal;
$labels-border-radius: $border-radius-base;

// Mobile app promo section
$m-promo-section-gutter-v: 4.5rem;
$m-promo-section-font-size: 1rem;
$m-promo-section-text-mb: 3rem;
$m-promo-section-text-align-gfb: left;
$m-promo-section-image-margin-gfb: 0 auto;

// Select
$m-select-padding: 6px 30px 6px 12px;

// Close
$close-font-size: 1.8rem;

// Floating Action Button
$m-floating-action-btn-font-size: 34px;

// Group nav module
$m-group-nav-font-size: 1.25rem;
$m-group-nav-font-weight: $font-weight-bold;
$m-group-nav-title-padding: 30px;
$m-group-nav-title-font-size: 2.5rem;
$m-group-nav-link-padding: 11px;
$m-group-nav-aside-link-border: 1px solid $separator-color;

// Group module
$m-group-header-text-transform: uppercase;

// Item module
$m-item-gutter-base: 15px;
$m-item-title-font-size: 1rem;
$m-item-description-font-size: 1rem;
$m-list-item-inner-border: true;

// List module
$m-list-title-font-size: 2.5rem;
$m-list-title-text-transform: uppercase;
$m-list-item-border-top: 1px solid $separator-color;

// Flex layout
$m-flex-layout-content-width: 70%;
$m-flex-layout-aside-width: 30%;

// Menu description
$m-menu-description-margin: 0 0 70px;
$m-menu-description-font-size: 1rem;

// Buttons
$btn-default-bg: transparent;
$btn-text-transform: none;
$btn-line-height-base: 1.45;
$btn-border-radius-base: $border-radius-base;
$btn-padding-base-vertical: 7px;
$btn-padding-base-horizontal: 30px;

$btn-padding-small-vertical: $btn-padding-base-vertical;
$btn-padding-small-horizontal: 10px;
$btn-font-size-small: 1rem;
$btn-line-height-small: $btn-line-height-base;

$btn-font-size-large: 1.125rem;
$btn-padding-large-vertical: 15px;
$btn-padding-large-horizontal: 50px;
$btn-line-height-large: $btn-line-height-base;

$btn-font-size-xlarge: 1.5rem;
$btn-padding-xlarge-vertical: 11px;

// Mobile navigation
$m-nav-toggle-font-size: 1rem;
$m-nav-toggle-padding: $btn-padding-base-vertical;
$m-nav-toggle-unstyled: false;
$mobile-navigation-breakpoint-max: none;
$mobile-navigation-dropdown-style-disc: false;
$mobile-navigation-flex-middle: true;
$mobile-navigation-font-size: 1.25rem;
$mobile-navigation-list-link-padding: 15px 50px 15px 50px;

// Forms
$form-group-margin-bottom: 25px;
$input-border: rgba(#000, 0.2);
$input-box-shadow: none;
$input-height-base: 50px;

// Card
$card-background: transparent;
$card-margin-bottom: 0;
$card-padding: 0;
$card-box-shadow: none;

// Map
$m-map-height: 584px;

// Shared dependencies
@import 'base/shared-themes-dependencies';

// Splitting
@import '~splitting/dist/splitting';
@import '~splitting/dist/splitting-cells';

// Custom styles
@import 'base/shared-essentials';
@import 'base/shared-themes';
@import 'modules/m-backdrop';
@import 'modules/m-articles';
@import 'modules/m-bubbles';
@import 'modules/m-fancy-boxes';
@import 'modules/m-special-box';
@import 'modules/m-media-cover';
@import 'modules/m-buttons';
@import 'modules/m-floating-action';

// Fiesta
@import 'base/fiesta/common';
@import 'modules/fiesta/m-top';
@import 'modules/fiesta/m-hero';
@import 'modules/fiesta/m-header';
@import 'modules/fiesta/m-nav';
@import 'modules/fiesta/m-flex-group';
@import 'modules/fiesta/m-indicators';
@import 'modules/fiesta/m-overlay';
@import 'modules/fiesta/m-menu-hero';
@import 'modules/fiesta/m-page-subheader';
@import 'modules/fiesta/m-heading';
@import 'modules/fiesta/m-main';
@import 'modules/fiesta/m-info-boxes';
@import 'modules/fiesta/m-breadcrumb';
@import 'modules/fiesta/m-boxes';
@import 'modules/fiesta/m-info-box';
@import 'modules/fiesta/m-icon-group';
@import 'modules/fiesta/m-icon-item';
@import 'modules/fiesta/m-section';
@import 'modules/fiesta/m-promo-boxes';
@import 'modules/fiesta/m-menu-teaser';
@import 'modules/fiesta/m-testimonials';
@import 'modules/fiesta/m-grid-gallery';
@import 'modules/fiesta/m-slider';
@import 'modules/fiesta/m-advanced-layout';
@import 'modules/fiesta/m-list-vertical';
@import 'modules/fiesta/m-magazine';
@import 'modules/fiesta/m-contact';
@import 'modules/fiesta/m-checkout';
@import 'modules/fiesta/m-footer';

.btn-invert {
  transition: all $base-duration;
}

.btn-xlg {
  @include button-size($btn-padding-xlarge-vertical, $btn-padding-base-horizontal, $btn-font-size-xlarge, $btn-line-height-large, $btn-border-radius-large);
}
